.pdf-editor {
  max-height: 100vh;
  overflow: hidden;

  .pdf-content {
    position: relative;
    .popup {
      width: 100%;
    }
  }

  .Highlight__part,
  .AreaHighlight {
    background: #000;
    mix-blend-mode: normal;
  }

  .textLayer {
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
    // mix-blend-mode: normal;
    .highlight {
      background: var(--color-primary);
      &.selected {
        background: var(--color-positive);
      }
    }
  }

  .textLayer ::selection {
    background: rgba(50, 184, 144, 0.5);
  }

  .MouseSelection {
    background: rgba(50, 184, 144, 0.5);
  }

  .freeform {
    .page,
    .textLayer,
    .textLayer span {
      cursor: crosshair !important;
      user-select: none;
    }
  }

  #pdf-viewer {
  }

  #navigation {
    height: 50px;
  }

  #thumbnailView {
    width: 200px;
    max-height: 100%;
    overflow-y: scroll;
    height: calc(100vh - 50px);

    .thumbnail-placeholder {
      width: 100px;
      height: 148px;
    }

    // thumbnail-page {
    //   &:hover {
    //     box-shadow: ;
    //   }
    // }
  }
}

#___reactour {
  button, button:focus{
    outline: none !important;
  }
}
